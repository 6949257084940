@use "src/common/styles/colors" as colors;
    
.cardContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .topBar{
        background: #2c3e50;
        color:#fafafa;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 20px;
        border-bottom: 1px solid colors.$border-color;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .title{
        display: flex;
        align-items: center;
        gap: 8px;
        svg{
            margin-top: 4px;
        }
    }

    .cardBody{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 20px;
        background-color: colors.$true-white;
    }
}

