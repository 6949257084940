
.container {

  /* Incoming message bubble (e.g., from others) */
  .chatui-message-bubble-in {
    background-color: #ffffff; /* WhatsApp style */
    color: #000000; /* WhatsApp style */
    border: 1px solid #e5e5ea; /* Instagram style */
  }

  /* Outgoing message bubble (e.g., from the user) */
  .chatui-message-bubble-out {
    background-color: #dcf8c6; /* WhatsApp style */
    color: #000000; /* WhatsApp style */
    border: 1px solid #34b7f1; /* Instagram style */
  }
  .custom-chat-ui .ChatFooter {
    display: none !important;
  }


  /* Custom styles for message sender name */
  .chatui-message-sender {
    font-weight: bold;
    margin-bottom: 2px;
  }

  .generationContainer {
    text-align: center;
    border: 1px solid #ececec;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;

    .conversationTitle{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .topBar{
      display: flex;
      gap:20px;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
    }

    .gptAnswer {
      width: 50%;
      margin: 30px auto;
      padding: 20px;
    }

    .seperator {
      margin: 20px 0;
      border-bottom: 1px solid #ececec;

    }

    .chatui-message-bubble {
      border-radius: 20px;
      padding: 10px;
      margin: 5px 0;
    }


    .generateAnswer {
      font-weight: 700;
      margin: 10px 0;
      font-size: 16px;
    }
  }
}

