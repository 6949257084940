.container {
  background-color: #f0f0f0;

  .pageItyself {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 900px;
    margin: 0 20%;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), /* Soft main shadow */
    0 1px 3px rgba(0, 0, 0, 0.06); /* Subtle edge shadow */
    min-height: 100vh;
    height: calc(100% + 120px);

    .pageContainer {
      .titleHolder {
        padding: 10px;
        font-size: 20px;
        font-weight: bold;
      }
      .titleHolder::before {
        content: "•"; /* Decorative character */
        color: #3498db; /* Icon color */
        font-size: 20px; /* Adjust size */
        margin-right: 8px; /* Spacing */
        display: inline-block;
        vertical-align: middle;
      }

      img {
        width: 100%;
      }

      .descHolder {
        padding: 10px;
        word-wrap: break-word; /* Break long words */
        overflow-wrap: break-word; /* Modern equivalent */
        white-space: pre-line; /* Keeps text from overflowing its container */
      }

      .buttonHolder {
        padding: 10px;
        display: flex;
        justify-content: center;
        margin-top: 20px;
      }
    }
  }
}