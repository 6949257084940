.container {
  .buttonHolder {
    display: flex;
    justify-content: flex-end;
  }

  .records {
    .startEndDate {
      display: flex;
      justify-content: center;
      font-size: 20px;
      margin: 20px 0;
      gap: 20px;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #505050;
      flex-wrap: wrap;

      .eachDate {
        text-align: center;
      }
    }

    .products {
      width: 100%;
      margin-top: 20px;

      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: center;
      }

      .productHolder {
        width: 100%;

        display: block;

        .eachProduct {
          margin-bottom: 10px;
          background-color: #101010;
          width: 100%;
          display: flex;
          border-radius: 10px;

          .productContainer {
            display: flex;
            flex-wrap: wrap;

            .imagePost {
              margin-top: 5px;

              img {
                width: 120px;
                height: 120px;
                object-fit: cover;
                border-radius: 5px;
              }
            }

            .buttonSample {
              margin-top: 8px;
            }

            .info {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-left: 10px;


              .titleInfo {
                font-size: 15px;
                font-weight: 700;
              }

              .infoblock {
                display: flex;
                gap: 10px;

                .producValue {
                  font-size: 13px;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }

    .cardHolder {
      display: flex;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;

      .eachCard {
        .title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 20px;
          text-align: center;
        }
      }
    }
  }


}


