.container {
  .mainTitle {
    font-size: 14px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 10px;

  }

  .row {
    width: 100%;

    .urlRow {
      width: 100%;
      display: flex;
      gap: 15px;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 10px;

      .textArea {
        display: flex;
        flex-direction: column;
        gap: 5px;

      }

      .titleHolder {
        font-size: 12px;
        color: #000000;
        font-weight: 500;
        width: 130px;
        border: 1px dashed #ccc;
        border-radius: 5px;
        padding: 10px;

        .dayHolder {
          display: flex;
          gap:5px;
          align-items: center;
          .dayTitle {
            font-size: 11px;
            color: #808080;
            white-space: nowrap;
          }
          //border-bottom: 1px solid #ccc;
          margin-bottom: 5px;
        }

      }
    }

    .addButton {
      display: flex;
      justify-content: flex-start;
      cursor: pointer;


      .addButtonRow {
        display: flex;
        gap: 10px;
        align-items: center;
        border: 1px solid #E5E5E5;
        border-radius: 5px;
        padding: 10px;
        margin-top: 5px;
      }
    }
  }
}