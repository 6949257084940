.container {
  .linksHolder {
    margin-top: 10px;

    .linkItself {
      display: flex;
      justify-content: space-between;
      border: 1px solid #E5E5E5;
      border-radius: 10px;
      padding: 10px;
      margin: 5px 0;
      align-items: center;

      .iconHolder {
        display: flex;
        align-items: center;
        gap:2px;
      }

      .topHolder {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        align-items: center;

        .dateItself {
          font-size: 11px;
          font-weight: 500;
          color: #000000;
        }

        .linksDayTitle {
          font-size: 11px;
          color: #505050;
        }
      }

      .linkText {
        font-size: 11px;
        font-weight: 500;
        color: #000000;
      }
    }
  }
}