@use "src/common/styles/colors" as colors;

.uiAlertBodyCentered {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}


.uiRowClass {
  background: colors.$almost-white;
  border: 1px solid #ecf0f1;
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;

  .uiRowTitle {
    color: #2c3e50;
    font-weight: 600;
    font-size: 13px;
  }
}