.container {
  height: auto;
  text-align: center;

  .children {
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-weight: 500;
    }
  }
}

.buttonWrapper {
  .buttonsContainer {
    border-top: 1px solid #bdc3c7;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.cardContainer {
  margin: 0;
  .customCardBody {
    padding: 0;
  }
}





