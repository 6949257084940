@use "src/common/styles/colors" as colors;
.container {
    background: colors.$sun-flower !important;
    box-shadow: none !important;
}

.innerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: colors.$primary-dark-color;
}

.usernameAndAvatarContainer {
    display: flex;
    align-items: center;

    .username {
        font-size: 16px;
        font-weight: 600;
        margin-left: 20px;
    }
}

.logout {
    border: none;
    background: none;
    cursor: pointer;
    color:#707070;
}
