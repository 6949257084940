.container{
    .buttonHolder{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: 10px 0;
    }
}
.socialHolder{
    display: flex;
    align-items: center;
    gap:10px;
}