.uploaderContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap:30px;

  .datePickers {
    margin-top: -10px;
  }

  .filterBlock {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
  }
}