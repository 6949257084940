.header {
  font-size: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);

  .content {
    display: block;
    text-align: center;
    svg {
      width: 100px;
      height: 200px;
    }
  }
}