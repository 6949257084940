@use "src/common/styles/colors" as colors;

.container {
  margin: 30px;

  .formItself {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .categories {
      .title {
        font-size: 16px;
        font-weight: 700;
      }

      .subTitle {
        font-weight: 300;
        margin: 10px 0;
      }

      .checkBoxHolder {
        display: flex;
        gap:5px;
        align-items: center;
      }
    }
  }
}