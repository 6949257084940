.container{
  margin-top:30px;
  .title{
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .buttonHolder{
    margin: 10px 0;
    display: flex;
    gap:10px;
  }
  .checkBoxHolder{
    display: flex;
    align-items: center;
  }
}