.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  text-align: center;

  img {
    width: 100%;
  }

  .contentHolder {
    .eachField {
      margin: 20px 0;

      .title {
        font-size: 16px;
        font-weight: bold;
      }

      .desc {
        margin-top: 5px;
      }
    }
  }
}