.container {

  .results {
    display: block;

    .titleArea {
      display: flex;
      align-items: center;
      gap: 10px;

      .projectTitle {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
      }
    }
  }

  .mainTitle {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 13px;
    color: #000000;
    padding-bottom: 10px;

    .titleElement {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    border-bottom: 1px solid #ccc;
  }

  .content {
    margin-top: 15px;

    .dropdowns {
      display: flex;
      gap: 20px;
    }

    .linkResults {
      margin: 5px 0;
    }
  }
}