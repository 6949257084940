.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
  position: absolute;
  top: 0;

  img {
    width: 80%;
    margin-bottom: 20px;
  }

  .icons {
    display: flex;
    gap:20px;
    margin-bottom: 10px;
    a {
      color: black;
    }
  }
}