.container {
  .formHolder {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dropdowns {
      display: inline-flex;
      gap: 10px;
    }
  }

  .linksHolder {
    margin-top: 10px;
  }
}