.container {
  max-width: 25%;
  min-width: 250px;

  .contentHolder {
    .qrItself {
      position: relative;
      top: 100px;
    }

    .qrCode {

      margin-left: 15px;

      img {
        margin-top: 120px;
      }
    }

    .codePart {
      .copyButton {
        display: flex;
        margin-bottom: 10px;
      }

      font-size: 12px;
      font-family: 'Monaco', serif;
      word-wrap: break-word;
      padding: 10px 20px;
      background-color: #fafafa;
    }
  }
}
