.container {
  .uploadExcelPart {
    display: flex;
    align-items: center;
    gap:20px;
    .uploadTitle{
      font-size: 13px;
      font-weight: 600;
      color:#202020;
    }
  }
  .actionButtons{
    display: flex;
    gap: 20px;
  }
}

