@use "src/common/styles/colors" as colors;

.container {
  background-color: colors.$app-background;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;

  .logo {
    mix-blend-mode: multiply;
    margin-top: 100px;
  }
  .appDetails {
    font-size: 15px;
    text-align: center;
    background-color: #f5f5f5;
    padding: 20px;

    a {
      color: colors.$link-blue;
      font-weight: bold;
    }
  }
  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;




    .formElements {
      width: 400px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

}