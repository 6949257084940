@use "src/common/styles/colors" as colors;

.itemText {
  color: black;
  span {
    font-size: 11px;
    font-weight: 500;
  }
}


a {
  text-decoration: none;
}

.selectedItem {
  background: colors.$primary-color;
  margin: 0 9px;
  border-radius: 5px;
  .itemText {
    color: colors.$app-background !important;
  }

  .icon {
    color: colors.$app-background !important;
  }
}


.notSelectedItem{
  //background: colors.$primary-text-color;
  .itemText {
    color: colors.$primary-text-color !important;
  }
  .icon {
    color: colors.$primary-text-color !important;
  }
}