.container {
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
  }

  .generalContainer {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;

    .formContainer {
      .topElements {
        display: flex;
        flex-direction: column;
        gap:10px;
      }

      .statusContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 15px 0;

        .statusTitle {
          font-weight: 700;
        }

      }

      .switcher {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .formElementBig {
        width: 520px;
      }

      .formElementSmall {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin: 15px 0;
      }

      .doubleElement {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 520px;
      }

      .dateContainer {
        display: flex;
        gap: 20px;
        width: 100%;
        flex-wrap: wrap;
      }

    }
  }
}