.container {
  .formContainer {
    display: flex;
    //justify-content: space-between;

    .formElements {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;
      .filters {
        display: flex;
        gap: 15px;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
      }
      .datePickers{
        margin-bottom: 10px;
      }
      .titleHolder {
        display: flex;
        gap: 20px;
        width: 100%;
        flex-wrap: wrap;
     /*   div{
          width: 100%;
        }*/
      }
      .offerHolder{
        display: flex;
        flex-direction: column;
        gap:15px;
      }
      .uploadPromoCodeContainer{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        flex-wrap: wrap;
      }
    }

  }
}