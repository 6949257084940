.container {
  .buttonHolder {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }
}

.updateCell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.socialHolder {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tagsHolder {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  max-height: 80px;
  overflow-y: auto;
}