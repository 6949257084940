.container {
  .doubleElement {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    .MuiFormControl-root {
      width: 45%;
    }
  }
}