.container {
  display: flex;
  gap: 20px;
  align-items: center;

  .fileName {
    font-size: 11px;
    margin: 10px 0;
  }

  .imageHolder {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    justify-content: center;
    object-fit: cover;
    display: flex;
    img {
      width: 250px;
      height: auto;
    }
  }
}