.container {
  display: flex;
  gap: 100px;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 20px;

  .secondPart {
    .fields {
      display: flex;
      gap: 40px;
      flex-wrap: wrap;
    }

    .tagsBlock {
      margin-top: 20px;
      max-width: 800px;

      .tagsHolder {
        display: flex;
        gap: 5px;
        align-items: center;
        margin-top: 10px;
        flex-wrap: wrap;
      }
    }
  }

  .titleBlock {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    gap: 10px;
  }

  .eachBlock {
    max-width: 400px;

    .contentBody {
      margin-top: 10px;
      max-width: 250px;

      div {
        margin: 5px 0;
      }

    }

    .contentBodyForm {
      margin-top: 10px;

      div {
        margin: 5px 0;
      }
    }
  }
}

