@use "src/common/styles/colors" as colors;

.container {
  background-color: colors.$app-background;
  //background-image: url("../../images/gecko_big_logo.webp") re;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  color: colors.$primary-text-color;

  .logo {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 50px;
    img {
      object-fit: cover;
      width: 300px;
      height: auto;
    }
  }
}

.loginBox {
  border-radius: 4px; // TODO: replace with variable
  //background-color: colors.$almost-white;
  width: 100%;
  height: auto;
  position: relative;
  top: 46%;
  transform: translateY(-50%);
  margin: 0 auto;
  max-width: 390px;
  padding-bottom: 10px;

  .headerContainer {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: colors.$card-title-bg-gray;
    padding: 0;
    height: 55px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .headerText {
    width: 100%;
    text-align: left;
    font-size: 18px;
    padding: 30px;
    font-weight: 600;
  }

  .body {
    display: flex;
    flex-direction: column;
    padding: 35px;
  }

  .inputField {
    margin-bottom: 20px;
  }

  .errorMessage {
    margin-bottom: 20px;
  }

  .helpNote {
    display: flex;
    flex-direction: column;
    text-align: left;
    min-width: 90px;
    margin-top: 25px;
    line-height: 1.45;
  }

  .helpNoteTitle {
    font-size: 14px;
    font-weight: 500;
  }

  .helpNoteContent {
    font-size: 13px;
  }
}