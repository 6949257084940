.container {
  .avatar {
    display: flex;
    align-items: center;
    gap: 20px;

    .avatarDesc {
      display: flex;
      gap: 15px;
      align-items: center;
    }
  }

  .sendMsgTitle {
    font-size: 13px;
    font-weight: 600;
    color: #202020;
    margin: 30px 0 5px 0;
  }

  .sendMsg {
    display: flex;
    flex-direction: column;
    gap:10px;
    font-size: 11px;
  /*  display: flex;
    align-items: center;
    gap: 20px;*/
  }
}