.container {
  .settingsContainer {
    display: block;
  }
  .tableLinks{
    max-width: 400px;
    height: 40px;
    overflow-y: auto;
  }
  .projectTypeContainer{
    display: flex;
    gap:5px;
    align-items: center;
    font-weight: 600;
    font-size:11px;
  }
}