.container{
    display: flex;
    width: 100%;
    gap:50px;
    padding: 20px;
    .newPageContainer{
        width: 350px;
        margin-top: 20px;
        border: 1px solid #d0d0d0;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap:10px;
        .newPageTitle{
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }
    .previewHolder{
        .previewTitle{
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .pageItself{
            max-width: 900px;
            margin: 20px 0;
            background-color: #f5f5f5;
            padding: 15px;
            .dragBlock{
                margin-bottom: 15px;
                border: 1px solid #d0d0d0;
                padding: 10px;
            }

            .imageHolder{
                display: flex;
                flex-direction: column;
            }
            img{
                width: 100%;
                height: auto;
                padding: 20px;
                margin-bottom: 10px;
            }
            .titleHolder{
                display: flex;
                flex-direction: column;
            }
        }
    }
}