@use "src/common/styles/colors" as colors;
.contentContainer {
  background-color: colors.$app-background;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow-y: auto;

}

.logoHolder {
  display: flex;
  justify-content: center;
  .networkLogo {
    width: 60%;
    margin-top: 20px;
  }
}