.reportSummary {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 10px;
  margin: 15px 30px;
}
.buttonHolder{
  display: flex;
  justify-content: flex-end;
}

.boxHolder {
  border: 1px solid #E5E5E5;
  border-radius: 10px;
  padding: 10px;
  text-align: center;

  .iconTitleHolder {
    display: flex;
    gap: 5px;
    align-items: center;


    .title {
      font-size: 11px;
      text-align: center;
      margin-bottom: 5px;
      color: #151515;
      font-weight: 600;
    }

    .moneyFormat {
      font-size: 13px;
      font-weight: 500;
      color: #404040;
    }

    .iconHolder {

    }
  }


}