$warning-color: #f39c12;
$true-white:#ffffff;
$almost-white:#fafafa;
$link-blue:#3498db;
$error-red:#e74c3c;
$border-color:#bdc3c7;
$light-gray:#bdc3c7;
$sun-flower:#f1c40f;
$card-title-bg-gray:#34495e;
$app-background:#ecf0f1;
$primary-text-color:#2c3e50;
$primary-dark-color:#101010;
$primary-color:#FF511E;
$primary-color-with-opacity: #FF511EEA;
