.container {
  width: 100%;

  .compContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    //take all space


    .uploadExcelPart {
      display: flex;
      gap: 50px;
      align-items: center;


      .alertMessage {
        //margin: 10px 0;
      }

      .uploadTitle {
        font-size: 13px;
        font-weight: 600;
        color: #202020;

      }
    }
  }
}