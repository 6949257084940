.container {


  .resultTable {
    margin: 20px 0 20px 0;

    .buttonHolder {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 20px 0;
    }

    .uploadHandler {
      .firstExcel {
        align-items: center;
        width: 80%;
        display: flex;
        gap: 30px;
        background-color: #fafafa;
        justify-content: space-between;
        border-radius: 20px;
        border: 1px solid #e5e5e5;
        padding: 20px;
        margin: 10px 0;
      }
    }

    .tableTitle {
      display: flex;
      gap: 10px;
      align-items: center;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .tagsBlock {
      margin-top: 20px;
      max-width: 440px;
      max-height: 150px;
      overflow-y: auto;

      .tagsHolder {
        display: flex;
        gap: 5px;
        align-items: center;
        margin-top: 10px;
        flex-wrap: wrap;
      }
    }
  }

  .topArea {
    display: flex;
    gap: 70px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;


    .userCard {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      padding: 20px;
      border-radius: 10px;
      width: 100%;

      .avatar {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 15px;
      }


      .userCardBlock {
        display: flex;
        //gap: 200px;
        margin: 5px 0;
        align-items: center;
        justify-content: flex-start;

        .userCardBlockTitle {
          font-size: 12px;
          width: 200px;
          color: #555555;
        }

        .userCardBlockDesc {
          font-size: 13px;
          color: #1f1f1f;
          font-weight: 500;
          text-align: left;
          min-width: 150px;

        }
      }
    }
  }
}