.container{
    .formContainer{
        display: flex;
        flex-direction: column;
        gap:15px;
        justify-content: center;
        width: 60%;
        .filters{
            display: flex;
            flex-direction: row;
            gap:15px;
            align-content: center;
        }
    }
}