
.container {
  position: fixed;
  left: 0;
  top:0;
  z-index: 1000000;
  background-color: #FFFFFFE7;
  width: 100%;
  height: 100vh;


  .spinner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    img{
      width: 200px;
      height: auto;
      padding: 30px;
    }
  }
}