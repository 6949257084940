@use "src/common/styles/colors" as colors;

.container {
  margin: 30px;

  .formItself {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .infoMsg {
      font-size: 12px;
      max-width: 330px;
      margin-top: 2px;
      color:colors.$primary-color;
    }

    .kvkkHolder {
      display: flex;
      align-items: center;
      .kvkkText {
        font-size: 13px;
        font-weight: 600;
        margin-top: 1px;
        color: colors.$link-blue;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .categories {
      .title {
        font-size: 16px;
        font-weight: 700;
      }

      .subTitle {
        font-weight: 300;
        margin: 10px 0;
      }

      .checkBoxHolder {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
  }
}