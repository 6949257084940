@use "src/common/styles/colors" as colors;
.container{
    .loginBox{
        .body{
            display: flex;
            flex-direction: column;
            gap:15px;
            max-width: 50%;
        }
    }
}