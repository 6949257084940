.container {
  margin-bottom: 15px;

  a {
    color: inherit;
    text-decoration: none;
    font-weight: 600;
  }

  a:hover {
    text-decoration: underline;
  }
}

