@use "src/common/styles/colors" as colors;

.container {
  margin: 30px;

  .formItself {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .fieldInfo{
      font-size: 11px;
      font-style: italic;
      color:colors.$card-title-bg-gray;
      margin-top: 2px;
    }
    .checkBoxHolder {
      display: flex;
      gap:5px;
      align-items: center;
    }

    .categories {
      .title {
        font-size: 16px;
        font-weight: 700;
      }

      .subTitle {
        font-weight: 300;
        margin: 10px 0;
      }

      .checkBoxHolder {
        display: flex;
        gap:5px;
        align-items: center;
      }
    }
  }
}